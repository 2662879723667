import { ElementRef, Injectable } from '@angular/core';
import * as tf from '@tensorflow/tfjs';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as mpControl from '@mediapipe/control_utils';
import * as mpCamera from '@mediapipe/camera_utils';
import { ToastController } from '@ionic/angular';
import { AnaliseModel } from '../models/analise.model';
import { FileTypeEnum } from '../models/enums/filetype.enum';
import { StatusEnum } from '../models/enums/status.enum';
import { FileModel } from '../models/file.model';
import { DataService } from './data-service.service';
import { FrameModel } from '../models/frame.model';
import { EyeModel } from '../models/eye.model';

@Injectable({
    providedIn: 'root'
})
export class AnaliseService {

    private _predictions;
    private _fpsControl;
    private _camera;
    private _faceMesh;

    private _cameraOptions = {
        facingMode: 'user'
    }

    constructor(
        private dataService: DataService,
        public toastController: ToastController) {
    }


    public getList(): AnaliseModel[] {
        return this.dataService.getItems();
    }

    public getFileTypeByEnum(type: FileTypeEnum) {
        switch (type) {
            case FileTypeEnum.CAMERA:
                return { label: 'CAMERA', icon: 'videocam-outline' };
            case FileTypeEnum.IMAGE:
                return { label: 'IMAGE', icon: 'image-outline' };
            default:
                return { label: 'VIDEO', icon: 'film-outline' };
        }
    }

    uploadFile(event, video: ElementRef, image: ElementRef) {
        const files = event.target.files;

        // check videos (can upload only one per time)
        if (!files.length) {
            this.toastError('No files to analise.');
            return;
        }

        for (let i = 0; i < files.length; i++) {
            this.startNewAnalise(files[i], video, image);
        }

    }

    public async toastError(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    public async startNewAnalise(file, videoElement: ElementRef, imageElement: ElementRef) {
        // const file = event.target.files[0];

        this._faceMesh = await faceLandmarksDetection.load(
            faceLandmarksDetection.SupportedPackages.mediapipeFacemesh, {
            detectionConfidence: 0.9,
            returnTensors: false,
            flipHorizontal: false,
            triangulateMesh: true,
            predictIrises: true,
            maxNumFaces: 1,
            minDetectionConfidence: 0.7,
            minTrackingConfidence: 0.7
        });

        console.log(file);

        let result = new AnaliseModel();
        result.status = StatusEnum.LOADING;
        result.fileInfo = new FileModel();
        result.fileInfo.lastModified = file.lastModified;
        result.fileInfo.lastModifiedDate = file.lastModifiedDate;
        result.fileInfo.name = file.name;
        result.fileName = file.name;
        result.fileInfo.size = file.size;
        result.fileInfo.type = file.type;

        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
            // get the blob of the image:
            let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
            // create blobURL, such that we could use it in an image element:
            let blobURL: string = URL.createObjectURL(blob);
            // this.toastError(blobURL);

            if (result.fileInfo.type.indexOf('video/') > -1) {
                result.fileType = FileTypeEnum.VIDEO;
            } else {
                result.fileType = FileTypeEnum.IMAGE;
                imageElement.nativeElement.src = blobURL;
                this.sleep(200).then(async () => {
                    this.analiseImage(result, file, imageElement);
                });
                this.dataService.appendItem(result);
            }


            // console.log('playing...', this._fpsControl);
            // videoElement.nativeElement.src = URL.createObjectURL(blob);
            // videoElement.nativeElement.muted = true;

            // this.dataService.appendItem(result);

            // this.sleep(600).then(async () => {
            //     // this._fpsControl = new mpControl.FPS();
            //     // this._camera = new  mpCamera.Camera(videoElement.nativeElement, null);
            //     // this._camera.start();

            //     // const predictions = await this._model.estimateFaces({
            //     //     input: videoElement.nativeElement
            //     // });

            //     // this._predictions = predictions;
            //     videoElement.nativeElement.play();

            //     // console.log(predictions);

            // });
        };

        reader.onerror = (error) => {
            this.toastError(error);
        };
    }



    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    videoEnded(event) {
        console.group('Video ended');
        console.log(this._predictions, event);
        console.groupEnd();
    }

    onResults(event) {
        console.log('results', event);

    }

    onFrame() {
        console.group('onFrame');
        console.log(this._predictions);
        console.groupEnd();
    }

    async analiseImage(context: AnaliseModel, file, imageElement) {

        // console.log(imageElement);

        const canvas = document.createElement('canvas'); // create a canvas
        // canvas.setAttribute('id', '__' + item.elapsedTime.toString())
        // canvas.style.visibility = 'hidden';
        const ctx = canvas.getContext('2d'); // get its context
        canvas.width = imageElement.nativeElement.width; // set its size to the one of the video
        canvas.height = imageElement.nativeElement.height;
        ctx.drawImage(imageElement.nativeElement, 0, 0); // the video

        // console.log(ctx, canvas.width, canvas.height);
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height);


        const predictions = await this._faceMesh.estimateFaces({
            input: data
        });

        context.totalFrames = 1;
        const frame = new FrameModel();
        frame.content = data;
        frame.width = canvas.width;
        frame.height = canvas.height;
        context.status = StatusEnum.FINISHED;

        if (predictions.length) {
            frame.annotations = predictions[0].annotations;
            context.pupilFrames++;
            frame.leftEye = new EyeModel(true);
            frame.leftEye.irisPoints = frame.annotations.leftEyeIris
            this.getEyesPoints(frame.leftEye, frame);
            // frame.leftEye.eyePoints = this.getEyesPoints(frame.annotations.leftEyeUpper0, frame.annotations.leftEyeLower0);
            frame.leftEye.checkPoints = frame.leftEye.eyePoints;

            frame.rightEye = new EyeModel();
            frame.rightEye.irisPoints = frame.annotations.rightEyeIris
            // frame.rightEye.eyePoints = this.getEyesPoints(frame.annotations.rightEyeUpper0, frame.annotations.rightEyeLower0);
            this.getEyesPoints(frame.rightEye, frame);
            frame.rightEye.checkPoints = frame.rightEye.eyePoints;

            context.validFrames.push(frame);
        }

        context.frames.push(frame);
        console.log(predictions, context);
    }

    private getEyesPoints(eye: EyeModel, frame) {

        const upperList = eye.leftEye ?
            frame.annotations.leftEyeUpper0 : frame.annotations.rightEyeUpper0;

        const lowerList = eye.leftEye ?
            frame.annotations.leftEyeLower0 : frame.annotations.rightEyeLower0;

        eye.axisX0 = upperList[0];
        eye.axisX1 = upperList[6];
        eye.axisY0 = lowerList[4];
        eye.axisY1 = upperList[3];
        eye.calculateDistances();
    }


}
