export class HelperService {

    public static getLatLngCenter(arr) {
        var x = arr.map(xy => xy[0]);
        var y = arr.map(xy => xy[1]);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        return [cx, cy];
    }

    public static getAngle(item1, item2) {
        const cx = item1[0];
        const cy = item1[1]

        const ex = item2[0];
        const ey = item2[1]

        const dy = ey - cy;
        const dx = ex - cx;
        var theta = Math.atan2(dy, dx); // range (-PI, PI]
        // theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    }

    public static getDistanceBetweenPoints(item1, item2) {
        if (item1 && item2) {
            return Math.hypot(item2[0] - item1[0], item2[1] - item1[1], item2[2] - item1[2])
            // Math.sqrt(Math.pow((item1[0] - item2[0]), 2) + Math.pow((item1[1] -
            //     item2[1]), 2));
        }
        return null;
    }

    public static getPercentage(partialValue, totalValue) {
        if (!totalValue || totalValue == 0) {
            return 0;
        }

        return (100 * partialValue) / totalValue;
    }

    public static calcDiffPercentage(value1, value2) {
        return HelperService.getPercentage(
          value1 > value2 ? value1 - value2 : value2 - value1,
          value1 + value2);
      }
}