import { HelperService } from "../services/helper.service";
import { GazeEnum } from "./enums/gaze.enum";

export class EyeModel {

    constructor(private _leftEye = false) { }

    public leftEye = this._leftEye;
    public irisPoints = [];
    public eyePoints = [];
    public checkPoints = [];
    public gaze: GazeEnum = GazeEnum.NONE;

    public irisSize: number = 0;
    public eyeSize: number = 0;

    public axisX0: number;
    public axisX1: number;
    public axisY0: number;
    public axisY1: number;

    public calculateDistances(): void {
        this.distanceX0 = HelperService.getDistanceBetweenPoints(this.axisX0, this.getIrisCenterPosition());
        this.distanceX1 = HelperService.getDistanceBetweenPoints(this.axisX1, this.getIrisCenterPosition());
        this.distanceY0 = HelperService.getDistanceBetweenPoints(this.axisY0, this.getIrisCenterPosition());
        this.distanceY1 = HelperService.getDistanceBetweenPoints(this.axisY1, this.getIrisCenterPosition());

        this.eyePoints = [];
        this.eyePoints.push(this.axisX0);
        this.eyePoints.push(this.axisX1);
        this.eyePoints.push(this.axisY0);
        this.eyePoints.push(this.axisY1);

        this.eyeSize = this.distanceX0 + this.distanceX1 + this.distanceY0 + this.distanceY1;
        this.irisSize = 0;
        let lastIrisPosition = this.irisPoints[0];

        this.irisPoints.forEach(element => {
            this.irisSize = HelperService.getDistanceBetweenPoints(lastIrisPosition, element);
            lastIrisPosition = element;
        });

        this.relativeDistanceX0 = HelperService.getPercentage(this.distanceX0, this.eyeSize);
        this.relativeDistanceX1 = HelperService.getPercentage(this.distanceX1, this.eyeSize);
        this.relativeDistanceY0 = HelperService.getPercentage(this.distanceY0, this.eyeSize);
        this.relativeDistanceY1 = HelperService.getPercentage(this.distanceY1, this.eyeSize);
    }

    public distanceX0: number;
    public distanceX1: number;
    public distanceY0: number;
    public distanceY1: number;

    public relativeDistanceX0: number;
    public relativeDistanceX1: number;
    public relativeDistanceY0: number;
    public relativeDistanceY1: number;

    public getEyeCenter() {
        return this.eyePoints ? HelperService.getLatLngCenter(this.eyePoints) || [] : [];
    }

    public getIrisCenterPosition() {
        return this.irisPoints && this.irisPoints.length ? this.irisPoints[0] : [];
    }

    public getIrisCenterDistance() {
        if (this.irisPoints && this.irisPoints.length) {

            // const irisCenterPosition = this.irisPoints[0];
            // const eyeCenterPosition = this.getEyeCenter();

            // let distance = 0;

            // let result = [];
            // let i = 0;
            // const eyeSize = this.getEyeSize();

            // this.checkPoints.forEach(element => {
            //     result.push({
            //         id: i,
            //         distance: HelperService.getDistanceBetweenPoints(element, irisCenterPosition),
            //         relativeDistance: HelperService.getPercentage(distance, eyeSize)
            //     });
            //     i++;
            // });

            return {
                x0: this.distanceX0,
                x1: this.distanceX1,
                y0: this.distanceY0,
                y1: this.distanceY1

            };

            // // const distance = HelperService.getDistanceBetweenPoints(eyeCenterPosition, irisCenterPosition);
            // const angle = HelperService.getAngle(eyeCenterPosition, irisCenterPosition);
            // const relativeDistance = HelperService.getPercentage(distance, eyeSize);
            // const relativeAngle = HelperService.getPercentage(angle, eyeSize);

            // return {
            //     distance: distance,
            //     angle: angle,
            //     eyeSize: eyeSize,
            //     relativeDistance: relativeDistance,
            //     relativeAngle: relativeAngle
            // };
        }

        return null;
    }

    public getEyeSize() {
        if (this.eyePoints && this.eyePoints.length) {
            let result = 0;

            this.eyePoints.forEach(element => {
                result += element[0] + element[1] + element[2];
            });

            return result;
        }

        return null;
    }

    public getRelativeDistance() {

    }


    public getEyeAxisPosition() {

        const xlenght = this.relativeDistanceX0 + this.relativeDistanceX1;
        const ylenght = this.relativeDistanceY0 + this.relativeDistanceY1;

        const x0 = HelperService.getPercentage(this.relativeDistanceX0, xlenght);
        const x1 = HelperService.getPercentage(this.relativeDistanceX1, xlenght);
        const y0 = HelperService.getPercentage(this.relativeDistanceY0, ylenght);
        const y1 = HelperService.getPercentage(this.relativeDistanceY1, ylenght);

        const directions = this.getGazeCardinal(x0, x1, y0,y1);

        return directions;
    }

    private getGazeCardinal(x0, x1, y0, y1) {
        const curve = 8; // percentual da posicao dos eixos a considerar na avaliacao de gaze

        const baseValue = 50;

        const diffX = HelperService.calcDiffPercentage(x0, x1);
        const diffy = HelperService.calcDiffPercentage(y0, y1);

        let xDirection = 'center';
        let yDirection = 'center';

        if (x0 < (baseValue-curve)){
            xDirection = !this.leftEye ?  'right' : 'left';
        } else if (x0 >= (baseValue+curve)){
            xDirection = !this.leftEye ?  'left' : 'right';
        }

        if (y0 < (baseValue-curve)){
            yDirection = 'bottom';
        } else if (y0 >= (baseValue+curve)){
            yDirection = 'top';
        }

        return {x: xDirection, y: yDirection, x0, x1, y0, y1};

    }


}