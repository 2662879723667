export enum GazeEnum {
    NONE,
    CENTER_TOP,
    CENTER_MID,
    CENTER_LOW,
    LSR_RIO,
    LLR_RMR,
    LIR_RSO,
    RIR_LSO,
    RLR_LMR,
    RSR_LIO
}