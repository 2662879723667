import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AnaliseService } from './services/analise.service';
import { DataService } from './services/data-service.service';

import { FlexLayoutModule } from '@angular/flex-layout';
import { GazeService } from './services/gaze.service';

import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AnaliseService,
    DataService,
    GazeService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
