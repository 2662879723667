import { GazeEnum } from "./enums/gaze.enum";
import { EyeModel } from "./eye.model";

export class FrameModel {
    public height = 0;
    public width = 0;
    public havePupil = false;
    public haveStrabismus = false;
    public content: any;
    public annotations: any;
    public gaze: GazeEnum = GazeEnum.NONE;
    public elapsedTime = 0;

    public leftEye: EyeModel = new EyeModel(true);
    public rightEye: EyeModel = new EyeModel();

    public base64Avatar?: string;

    public evaluteEyes(){
        if (this.leftEye && this.rightEye){
            this.haveStrabismus = (this.leftEye.getEyeAxisPosition().x != this.rightEye.getEyeAxisPosition().x) || 
            (this.leftEye.getEyeAxisPosition().y != this.rightEye.getEyeAxisPosition().y);

            return this.haveStrabismus;
        }

        return null;
    }
}