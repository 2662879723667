import { FileTypeEnum } from "./enums/filetype.enum";
import { FrameModel } from "./frame.model";
import * as uuid from 'uuid';
import { StatusEnum } from "./enums/status.enum";
import { FileModel } from "./file.model";

export class AnaliseModel {
    public readonly id: string = uuid.v4();
    public name: string;
    public createdAt: Date = new Date();
    public fileName: string;
    public fileType: FileTypeEnum = FileTypeEnum.VIDEO;
    public videoDuration = 0;
    public totalFrames = 0;
    public pupilFrames = 0;
    public strabimusFrames = 0;
    public errorFrames = 0;
    public normalFrames = 0;
    public frames: FrameModel[] = [];
    public validFrames: FrameModel[] = [];
    public status: StatusEnum = StatusEnum.OPEN;
    public statusText: string;
    public fileInfo: FileModel;

    public copyValidFrames() {
        const filtered = this.frames || [];
        this.totalFrames = this.frames.length;

        filtered.forEach(element => {
            if (element.havePupil) {
                this.pupilFrames = filtered.length;
                this.validFrames.push(element);
            }
        });
    }
}