import { Injectable } from '@angular/core';
import { AnaliseModel } from '../models/analise.model';
import { FileTypeEnum } from '../models/enums/filetype.enum';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _analises: AnaliseModel[] = [];

  constructor() { 
    // const test = new AnaliseModel();
    // test.name = 'testing models';
    // test.fileType = FileTypeEnum.CAMERA;

    // const test2 = new AnaliseModel();
    // test2.name = 'testing models 2';
    // test2.fileType = FileTypeEnum.IMAGE;

    // this._analises.push(test);
    // this._analises.push(test2);
  }

  getItems(){
    return this._analises || [];
  }

  appendItem(item){
    this._analises.push(item);
  }
}
