import { ElementRef, EventEmitter, Injectable, Output } from "@angular/core";
import { AnaliseModel } from "../models/analise.model";
import { StatusEnum } from "../models/enums/status.enum";
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { FrameModel } from "../models/frame.model";
import { EyeModel } from "../models/eye.model";
import { FileModel } from "../models/file.model";
import { HelperService } from "./helper.service";
import { DataService } from '../services/data-service.service';

const errorMessages = {
    _INVALID_FILE_TYPE: 'File parameter must be a valid video.',
    _INVALID_FILE_SIZE: 'File parameter must be a valid video.',
    _INVALID_VIDEO_HTML_ELEMENT: 'videoElement parameter is requerid.',
    _INVALID_CANVAS_HTML_ELEMENT: 'canvasElement parameter is requerid.',
}

@Injectable({
    providedIn: 'root'
})
export class GazeService {

    @Output() onFrameProcessed: EventEmitter<FrameModel> = new EventEmitter<FrameModel>();

    private _faceMesh;

    constructor(private _dataService: DataService) {
    }

    public async startEngine() {
        this._faceMesh = await faceLandmarksDetection.load(
            faceLandmarksDetection.SupportedPackages.mediapipeFacemesh, {
            detectionConfidence: 0.9,
            returnTensors: false,
            flipHorizontal: false,
            triangulateMesh: true,
            predictIrises: true,
            selfie: true,
            maxNumFaces: 1,
            minDetectionConfidence: 0.8,
            minTrackingConfidence: 0.8
        });
    }

    public selectedAnalisys: AnaliseModel;

    public async processVideoFile(file, result: AnaliseModel, videoElement: ElementRef, canvasElement: ElementRef, fromCamera = true): Promise<AnaliseModel> {
        result = result ? result : new AnaliseModel();
        result.status = StatusEnum.LOADING;
        result.videoDuration = videoElement.nativeElement.duration;

        if (!fromCamera) {
            if (!file || file.type.indexof('video/') == -1) {
                result.status = StatusEnum.ERROR;
                result.statusText = errorMessages._INVALID_FILE_TYPE;
                console.group('file type error');
                console.log(errorMessages._INVALID_FILE_TYPE, file);
                console.groupEnd();
                return result;
            }

            if (file.size == 0) {
                result.status = StatusEnum.ERROR;
                result.statusText = errorMessages._INVALID_FILE_SIZE;
                console.group('file size error');
                console.log(errorMessages._INVALID_FILE_SIZE, file);
                console.groupEnd();
                return result;
            }
        }

        if (!videoElement) {
            result.status = StatusEnum.ERROR;
            result.statusText = errorMessages._INVALID_VIDEO_HTML_ELEMENT;
            console.group('videoElement error');
            console.log(errorMessages._INVALID_VIDEO_HTML_ELEMENT, videoElement);
            console.groupEnd();
            return result;
        }

        this.selectedAnalisys = result;

        const videoFile = file[0];

        result.fileName = videoFile.name;
        result.fileInfo = new FileModel();
        result.fileInfo.name = videoFile.name;
        result.fileInfo.size = videoFile.size;
        result.fileInfo.type = videoFile.type;
        result.fileInfo.lastModified = videoFile.lastModified;

        if (videoFile) {
            /* videoElement.nativeElement.onplay = (data) => {
                videoElement.nativeElement.muted = true;
                
            } */

            console.group('Capturing frames');
            result.status = StatusEnum.PUPIL_FRAMES;

            videoElement.nativeElement.addEventListener('ended', async (event) => {
                this.videoEnded();
            });

            await videoElement.nativeElement.play();
        }

        return result;
    }

    getVideoDimensions(element, isWidth = false) {

        return element.nativeElement.videoWidth + element.nativeElement.videoHeight > 0 ? isWidth ?
            element.nativeElement.videoWidth : element.nativeElement.videoHeight
            : isWidth ? 1280 : 720;
    }

    private counter = 0;
    async captureFrames(event, videoElement) {
        // const videoElement = videoEle.nativeElement; //event.srcElement;

        let frameCounter = 1;
        const maxFrames = 6;
        const timming = 50;

        for (var i = 0; i < maxFrames - 1; i++) {
            this.sleep(frameCounter <= 1 ? 0 : timming).then(async x => {
                this.counter++;
                if (this.selectedAnalisys) {
                    this.selectedAnalisys.totalFrames++;
                    // console.log(this.counter, videoElement, videoElement.nativeElement.currentTime, 'trying...');

                    if (this.getVideoDimensions(videoElement, true) > 0 && this.getVideoDimensions(videoElement, false) > 0) {
                        if (videoElement.nativeElement.currentTime <= 4) {
                            // console.log(this.counter, videoElement.nativeElement.currentTime);

                            const frame = new FrameModel();
                            frame.elapsedTime = videoElement.nativeElement.currentTime;

                            const canvas = document.createElement('canvas'); // create a canvas
                            const ctx = canvas.getContext('2d'); // get its context
                            canvas.width = this.getVideoDimensions(videoElement, true);
                            canvas.height = this.getVideoDimensions(videoElement, false);
                            ctx.drawImage(videoElement.nativeElement, 0, 0); // the video
                            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            frame.content = data;
                            frame.width = this.getVideoDimensions(videoElement, true);
                            frame.height = this.getVideoDimensions(videoElement, false);

                            const predictions = await this._faceMesh.estimateFaces({
                                input: data
                            });

                            // this.selectedAnalisys.frames.push(frame);

                            if (predictions && predictions.length) {
                                frame.annotations = predictions[0].annotations;
                                await this.processFrame(frame);
                            }

                            // const predictions = await this._faceMesh.estimateFaces({
                            //     input: videoElement.nativeElement
                            // });

                            // this.processPredictions(predictions, videoElement, videoElement.nativeElement.currentTime);
                        }
                    }
                }
            });

        }
    }

    async processFrame(frame) {
        this.selectedAnalisys.status = StatusEnum.PUPIL_FRAMES;

        frame.leftEye = new EyeModel(true);
        frame.leftEye.irisPoints = frame.annotations.leftEyeIris;
        this.getEyesPoints(frame.leftEye, frame);
        frame.leftEye.checkPoints = frame.leftEye.eyePoints;

        frame.rightEye = new EyeModel();
        frame.rightEye.irisPoints = frame.annotations.rightEyeIris;
        this.getEyesPoints(frame.rightEye, frame);
        frame.rightEye.checkPoints = frame.rightEye.eyePoints;

        frame.havePupil = true;

        frame.haveStrabismus = frame.leftEye.getEyeAxisPosition().x !== frame.rightEye.getEyeAxisPosition().x
            || frame.rightEye.getEyeAxisPosition().y !== frame.leftEye.getEyeAxisPosition().y;

        // console.log(frame.elapsedTime, frame.leftEye.getEyeAxisPosition(), frame.rightEye.getEyeAxisPosition(), frame.haveStrabismus);
        // console.log(frame.elapsedTime, predictions[0].faceInViewConfidence, frame.leftEye.getEyeAxisPosition(), frame.rightEye.getEyeAxisPosition(), frame.haveStrabismus);

        console.log(frame.haveStrabismus);
        await this.pushValidFrame(frame);

    }

    async processPredictions(predictions, videoElement, currentFrameTime) {
        if (predictions.length == 1) {
            this.selectedAnalisys.status = StatusEnum.GEOMETRY;
            this.selectedAnalisys.pupilFrames++;

            const frame = new FrameModel();
            frame.elapsedTime = currentFrameTime;

            const canvas = document.createElement('canvas'); // create a canvas
            const ctx = canvas.getContext('2d'); // get its context
            canvas.width = videoElement.nativeElement.offsetWidth;
            canvas.height = videoElement.nativeElement.offsetHeight;
            ctx.drawImage(videoElement.nativeElement, 0, 0); // the video
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
            frame.content = data;
            frame.width = videoElement.nativeElement.offsetWidth;
            frame.height = videoElement.nativeElement.offsetHeight;
            frame.annotations = predictions[0].annotations;

            frame.leftEye = new EyeModel(true);
            frame.leftEye.irisPoints = frame.annotations.leftEyeIris;
            this.getEyesPoints(frame.leftEye, frame);
            frame.leftEye.checkPoints = frame.leftEye.eyePoints;

            frame.rightEye = new EyeModel();
            frame.rightEye.irisPoints = frame.annotations.rightEyeIris;
            this.getEyesPoints(frame.rightEye, frame);
            frame.rightEye.checkPoints = frame.rightEye.eyePoints;

            frame.havePupil = true;

            frame.haveStrabismus = frame.leftEye.getEyeAxisPosition().x !== frame.rightEye.getEyeAxisPosition().x
                || frame.rightEye.getEyeAxisPosition().y !== frame.leftEye.getEyeAxisPosition().y;

            // console.log(frame.elapsedTime, predictions[0].faceInViewConfidence, frame.leftEye.getEyeAxisPosition(), frame.rightEye.getEyeAxisPosition(), frame.haveStrabismus);

            this.selectedAnalisys.validFrames.push(frame);
            // this.onFrameProcessed.emit(frame);
        }
    }

    async videoEnded() {
        console.group('Testing ...');

        const result = await this.processFrames();
        // console.log(result);

        // console.group('Summary');
        // console.log('Total captured frames: ', result.total);
        // console.log('Total pupil frames: ', result.pupilFrames);
        // console.log('Strabismus: ',
        //     result.regular,
        //     ' | Strabismus: ',
        //     result.strabismus,
        // );

        this.selectedAnalisys.status = StatusEnum.FINISHED;

        console.groupEnd();
        console.groupEnd();
        // console.log(this.selectedAnalisys);
        console.groupEnd();
        this._dataService.appendItem(result);
    }

    processFrames() {

        this.selectedAnalisys.frames.forEach(async frame => {
            if (frame.elapsedTime > 0.5) {
                this.selectedAnalisys.status = StatusEnum.PUPIL_FRAMES;

                const predictions = await this._faceMesh.estimateFaces({
                    input: frame.content
                });

                // console.log(predictions);
                if (predictions && predictions.length) {
                    frame.annotations = predictions[0].annotations;

                    frame.leftEye = new EyeModel(true);
                    frame.leftEye.irisPoints = frame.annotations.leftEyeIris;
                    this.getEyesPoints(frame.leftEye, frame);
                    frame.leftEye.checkPoints = frame.leftEye.eyePoints;

                    frame.rightEye = new EyeModel();
                    frame.rightEye.irisPoints = frame.annotations.rightEyeIris;
                    this.getEyesPoints(frame.rightEye, frame);
                    frame.rightEye.checkPoints = frame.rightEye.eyePoints;

                    frame.havePupil = true;

                    frame.haveStrabismus = frame.leftEye.getEyeAxisPosition().x !== frame.rightEye.getEyeAxisPosition().x
                        || frame.rightEye.getEyeAxisPosition().y !== frame.leftEye.getEyeAxisPosition().y;

                    // console.log(frame.elapsedTime, frame.leftEye.getEyeAxisPosition(), frame.rightEye.getEyeAxisPosition(), frame.haveStrabismus);
                    // console.log(frame.elapsedTime, predictions[0].faceInViewConfidence, frame.leftEye.getEyeAxisPosition(), frame.rightEye.getEyeAxisPosition(), frame.haveStrabismus);

                    this.pushValidFrame(frame);
                }
            }
        });

        this.selectedAnalisys.status = StatusEnum.FINISHED;

        return this.summary();
    }

    async pushValidFrame(frame) {
        const idx = this.selectedAnalisys.validFrames.length;

        // check for interference
        if (this.selectedAnalisys.validFrames && this.selectedAnalisys.validFrames.length > 2) {
            const previous = this.selectedAnalisys.validFrames[idx - 1];
            const base = this.selectedAnalisys.validFrames[idx - 2];
            if (base.haveStrabismus === frame.haveStrabismus && previous.haveStrabismus !== frame.haveStrabismus) {
                previous.haveStrabismus = frame.haveStrabismus;
            }
        }

        this.selectedAnalisys.validFrames.push(frame);
    }

    private getEyesPoints(eye: EyeModel, frame) {

        const upperList = eye.leftEye ?
            frame.annotations.leftEyeUpper0 : frame.annotations.rightEyeUpper0;

        const lowerList = eye.leftEye ?
            frame.annotations.leftEyeLower0 : frame.annotations.rightEyeLower0;

        eye.axisX0 = upperList[0];
        eye.axisX1 = upperList[6];
        eye.axisY0 = lowerList[4];
        eye.axisY1 = upperList[3];
        eye.calculateDistances();
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    public summary(analisys = null) {
        if (!analisys) {
            analisys = this.selectedAnalisys;
        }

        if (analisys) {
            let result = {
                total: analisys ? analisys.frames.length : this.selectedAnalisys.frames.length,
                pupilFrames: analisys ? analisys.validFrames.length : this.selectedAnalisys.validFrames.length,
                regular: analisys ? analisys.validFrames.filter(x => !x.haveStrabismus)?.length
                    : this.selectedAnalisys.validFrames.filter(x => !x.haveStrabismus)?.length,
                strabismus: analisys ? analisys.validFrames.filter(x => x.haveStrabismus)?.length
                    : this.selectedAnalisys.validFrames.filter(x => x.haveStrabismus)?.length,
                strabismus_possibility: 0
            };

            result.strabismus_possibility = HelperService.getPercentage(result.strabismus, result.pupilFrames);

            return result;
        }

        return null;
    }

    public getFrameAvatar(frame: FrameModel) {
        if (frame && frame.content && !frame.base64Avatar) {

            // create canvas object 
            const avatarDimension = 120;
            const distance = HelperService.getDistanceBetweenPoints(frame.annotations.midwayBetweenEyes[0], frame.annotations.noseBottom[0]);

            const canvasElement = document.createElement('canvas'); // create a canvas
            const ctxElement = canvasElement.getContext('2d'); // get its context
            canvasElement.width = frame.width;
            canvasElement.height = frame.height;
            ctxElement.putImageData(frame.content, 0, 0);

            // this.traceAxisLabel(frame.leftEye, ctxElement, distance / 5);
            // this.traceAxisLabel(frame.rightEye, ctxElement, distance / 5);
            this.checkEyes(frame.leftEye.getEyeCenter(), ctxElement, distance / 10, "rgba(255, 87, 34, 0.3)");
            this.checkEyes(frame.rightEye.getEyeCenter(), ctxElement, distance / 10, "rgba(255, 87, 34, 0.3)");


            const canvas = document.createElement('canvas'); // create a canvas
            const ctx = canvas.getContext('2d'); // get its context
            canvas.width = avatarDimension;
            canvas.height = avatarDimension;

            ctx.drawImage(canvasElement,
                frame.annotations.midwayBetweenEyes[0][0] - distance, frame.annotations.midwayBetweenEyes[0][1] - distance,
                distance * 2, distance * 2,
                0, 0,
                avatarDimension, avatarDimension);
            frame.base64Avatar = canvas.toDataURL();

        }
        return frame.base64Avatar;
    }

    public traceAxisLabel(eye, ctx, fontsize = 12) {
        if (eye.axisX0) {
            ctx.beginPath();
            //   ctx.strokeStyle = '#ffffff';
            ctx.font = fontsize.toString() + 'px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'red';

            ctx.fillText('X0', eye.axisX0[0], eye.axisX0[1]);
            ctx.fillText('X1', eye.axisX1[0], eye.axisX1[1]);
            ctx.fillText('Y0', eye.axisY0[0], eye.axisY0[1]);
            ctx.fillText('Y1', eye.axisY1[0], eye.axisY1[1]);
        }
    }


    private drawCircle(item1, item2, ctx) {
        const distance = HelperService.getDistanceBetweenPoints(item1, item2);
        console.log(distance);
        ctx.beginPath();
        ctx.arc(item1[0], item1[1], distance, 0, 2 * Math.PI);
        ctx.strokeStyle = 'orange';
        ctx.stroke();
    }
    private checkEyes(eyeItem, ctx, size = 5, fill = "rgba(255, 255, 255, 0.3)") {
        const hrLine = eyeItem;
        ctx.beginPath();
        ctx.arc(hrLine[0], hrLine[1], size, 0, 2 * Math.PI);
        ctx.fillStyle = fill;
        ctx.fill();
        // ctx.stroke();
    }
}